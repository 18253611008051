import React from 'react';
import '../../scss/index.scss';
import DefaultHeader from '../../components/DefaultHeader/DefaultHeader';
import { LeadSpace } from '@carbon/ibmdotcom-react';
import { Launch20 } from '@carbon/icons-react';
import Plx from 'react-plx';
import { textData, textData02, textDataLast, boxData, boxData02 } from './parallax-content';
import { Link } from "carbon-components-react";

const Landing = ()   => {
  return (
    <>
      <DefaultHeader />
      <div className="headerSpace">
        <LeadSpace
          style={{ backgroundColor: "#d0e0ff" }}
          size="medium"
          type="default"
          theme="white"
          title="Explorando a IA em sala de aula"
          copy="Um kit de atividades para incentivar a aprendizagem e introduzir conceitos de inteligência articifial nas escolas"
          gradient={false}
          image={{
            sources: [
              { src: require('../../images/bg01.png').default, breakpoint: 'md' },
            ],
            defaultSrc: require('../../images/ptech.svg').default,
            alt: 'lead space image',
          }}
        />
        <img className="ptech-img" alt="ptech" src={require('../../images/ptech.svg').default}/>
        <div className="container">
          <div className="pic01-container">
            <div className="pic01-text">
              <div>O que é o kit de atividades?</div>
              Com a infinidade de conteúdos sobre inteligência artificial disponíveis hoje em dia, definir quais deles devem ser introduzidos em sala de aula pode parecer um desafio. 
              Foi por isso que a nossa equipe, através de pesquisas acadêmicas e com professores das redes pública e privada de ensino no Brasil, definiu a abordagem ideal a ser seguida neste kit de atividades.
              <div>Siga as instruções abaixo para começar:</div>
            </div>
            <div className="pic01-img-txt-holder">
              <Plx
                className='StickyText'
                parallaxData={textData}
              >
                <div className="pic01">
                  <img src={require('../../images/illustration-01.svg').default} alt="pic01" />
                </div>
              </Plx>
            </div>
          </div>
          <div className="pic02-img-txt-holder">
            <Plx
              className='StickyText'
              parallaxData={textData}
            >
              <div className="pic02">
                <div>01</div>
                <img src={require('../../images/illustration-05.svg').default} alt="pic02" />
              </div>
            </Plx>
            <Plx
              className='StickyText'
              parallaxData={boxData02}
            >
              <div className="pic02-textbox">
                Cadastre-se no <Link href="https://students.yourlearning.ibm.com" target="_blank" rel="noreferrer" renderIcon={Launch20} size="lg">SkillsBuild</Link><br />
                Caso você já tenha cadastro, faça o seu login, e retorne para esta página.
              </div>
            </Plx>
          </div>
          <div className="pic03-img-txt-holder">
            <Plx
              className='StickyText'
              parallaxData={textData02}
            >
              <div className="pic03">
              <div>02</div>
                <img src={require('../../images/illustration-02.svg').default} alt="pic03" />
              </div>
            </Plx>
            <Plx
              className='StickyText'
              parallaxData={boxData}
            >
              <div className="pic03-textbox">
                Acesse <Link href="/jogos" size="lg">o kit de atividades</Link> e escolha qual jogo deseja começar para ser direcionado(a) à página de instruções.
              </div>
            </Plx>
          </div>
          <div className="pic05-img-txt-holder">
            <Plx
              className='StickyText'
              parallaxData={textDataLast}
            >
              <div className="pic05">
              <div>03</div>
                <img src={require('../../images/illustration-04.svg').default} alt="pic05" />
              </div>
            </Plx>
            <div className="pic05-textbox">
              Pronto! Agora é só compartilhar com seus alunos!
            </div>
          </div>
        </div>
        <div className="container-mobile">
          <div className="mobile-text">
            <div>O que é o kit de atividades?</div>
            Com a infinidade de conteúdos sobre inteligência artificial disponíveis hoje em dia, definir quais deles devem ser introduzidos em sala de aula pode parecer um desafio. 
            Foi por isso que a nossa equipe, através de pesquisas acadêmicas e com professores das redes pública e privada de ensino no Brasil, definiu a abordagem ideal a ser seguida neste kit de atividades.
            <div>Siga as instruções abaixo para começar:</div>
          </div>
          <div className="pic01 pic-mobile"></div>
          <div className="pic-textbox-mobile">
            Cadastre-se no <Link href="https://students.yourlearning.ibm.com" target="_blank" rel="noreferrer" renderIcon={Launch20} size="lg">SkillsBuild</Link><br />
            Caso você já tenha cadastro, faça o seu login, e retorne para esta página.
          </div>
          <div className="pic02 pic-mobile"></div>
          <div className="pic-textbox-mobile">
            Acesse <Link href="/jogos" size="lg">o kit de atividades</Link> e escolha qual jogo deseja começar para ser direcionado(a) à página de instruções.
          </div>
          <div className="pic04 pic-mobile"></div>
          <div className="pic-textbox-mobile">
            Ao escolher um item você será redirecionado para uma página com instruções de como aplicar em sala de aula e com o link de acesso para o jogo.
          </div>
          <div className="mobile-divide"></div>
          <div className="pic05 pic-mobile"></div>
          <div className="pic-textbox-mobile">
            Pronto! Agora é só compartilhar com seus alunos!
          </div>
        </div>
        <section className="finalSection">
          <p className="finalSectionTitle">O Projeto</p>
          <div className="aboutUsDiv">
            <p className="aboutUsText">
              Sabemos que inteligência artificial já é uma realidade e está cada vez mais presente em nosso dia a dia. Seja na área da saúde, com diagnósticos mais rápidos e precisos, nos meios digitais ou através de assistentes pessoais, as aplicabilidades dessa tecnologia são infinitas.
            </p>
            <p className="aboutUsText">
              Pensando nisso, a IBM Brasil e o Museu Catavento uniram esforços para incentivar a aprendizagem e introduzir conceitos de inteligência artificial para crianças e adolescentes das escolas públicas e privadas ao redor do Brasil.
            </p>
          </div>
        </section>
      </div>
    </>
  )
}

export default Landing

import { Launch20 } from '@carbon/icons-react';
export const card01 = {
  eyebrow: "",
  heading: "O que é Inteligência Artificial?",
  image: {
    classname: 'imgCard',
    defaultSrc: 'https://images.pexels.com/photos/2599244/pexels-photo-2599244.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    alt: 'Card Image',
  },
};

export const card02 = {
  eyebrow: "",
  heading: "Inteligência Artificial na prática",
  image: {
    classname: 'imgCard',
    defaultSrc: 'https://images.pexels.com/photos/1181244/pexels-photo-1181244.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    alt: 'Card Image',
  },
};
export const card03 = {
  eyebrow: "",
  heading: "IA: Aprendizado de Máquina para crianças",
  image: {
    classname: 'imgCard',
    defaultSrc: 'https://images.pexels.com/photos/4260483/pexels-photo-4260483.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    alt: 'Card Image',
  },
};

export const cta01 = {
  type: "external",
  href: "https://students.yourlearning.ibm.com/activity/ILB-PZXXWZEREVVQ28K2?channelId=CNL_LCB_1602866246968",
  icon: {
    src: Launch20,
  },
  iconPlacement: "right" 
};

export const cta02 = {
  type: "external",
  href: "https://students.yourlearning.ibm.com/activity/ILB-PZXXNXNKPNMZ1BZY?channelId=CNL_LCB_1602866246968",
  icon: {
    src: Launch20,
  },
  iconPlacement: "right" 
};

export const cta03 = {
  type: "external",
  href: "https://students.yourlearning.ibm.com/activity/URL-6798E6B491C6?channelId=CNL_LCB_1602866246968",
  icon: {
    src: Launch20,
  },
  iconPlacement: "right" 
};
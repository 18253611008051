import React from "react";
import {
  Header,
  HeaderName,
  HeaderGlobalBar,
  SkipToContent,
  HeaderNavigation,
  HeaderMenuItem
} from "carbon-components-react/lib/components/UIShell";

const DefaultHeader = () => (
  <Header aria-label="Carbon Tutorial">
    <SkipToContent />
    <HeaderName href="/" prefix="IBM">
      IA Edu
    </HeaderName>
    <HeaderNavigation aria-label="IA Edu">
      <HeaderMenuItem href="/jogos">Kit de atividades</HeaderMenuItem>
      <HeaderMenuItem href="/about">Nosso time</HeaderMenuItem>
    </HeaderNavigation>
    <HeaderGlobalBar/>
  </Header>
);

export default DefaultHeader;

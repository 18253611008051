import React from 'react';
import '../../scss/index.scss';
import DefaultHeader from '../../components/DefaultHeader/DefaultHeader';

const About = () => {
  return (
    <>
      <DefaultHeader />
      <div className="headerSpace">
        <div className="ourTeamDiv">
          <h2 className="ourTeamTitle">Nosso Time</h2>
          <div className="bx--grid ourTeamPics">
            <div className="bx--row row-align">
            <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/heloisa.png').default} alt="pessoa01" />
                  <h2>Heloisa Candello</h2>
                  <span>Research Scientist</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/alan.png').default} alt="pessoa01" />
                  <h2>Alan Braz</h2>
                  <span>Research Developer</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/eliana.png').default} alt="pessoa01" />
                  <h2>Eliana Basso</h2>
                  <span>Academic Initiative Program Manager</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/kevin.png').default} alt="pessoa01" />
                  <h2>Kevin Talarico</h2>
                  <span>Gamification Consultant</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/leila.png').default} alt="pessoa01" />
                  <h2>Leila Freitas</h2>
                  <span>ContentWriter</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/matheus.png').default} alt="pessoa01" />
                  <h2>Matheus Mendes</h2>
                  <span>Especialista em Educação - CSR América Latina</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/paula.png').default} alt="pessoa01" />
                  <h2>Paula Pereira</h2>
                  <span>Designer</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/raquel.png').default} alt="pessoa01" />
                  <h2>Raquel Klafke</h2>
                  <span>UX Designer</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/victoria.jpeg').default} alt="pessoa01" />
                  <h2>Victoria Julia</h2>
                  <span>UX Designer</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/marina.jpg').default} alt="pessoa01" />
                  <h2>Marina Ito</h2>
                  <span>Visual Designer</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/aline.jpg').default} alt="pessoa01" />
                  <h2>Aline Tri</h2>
                  <span>UX Designer</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/otavio.png').default} alt="pessoa01" />
                  <h2>Otávio Augusto</h2>
                  <span>Research Front-end Developer</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace">
                <div className="personCard">
                  <img src={require('../../images/felipe.jpeg').default} alt="pessoa01" />
                  <h2>Felipe Zuntini</h2>
                  <span>Software Architect</span>
                </div>
              </div>
              <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-1 cardSpace"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About

export const textData = [
  {
    start: '',
    duration: '1vh',
    properties: [
      {
        startValue: 0,
        endValue: -45,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
  {
    start: '',
    startOffset: '1vh',
    duration: '90vh',
    properties: [
      {
        startValue: 45,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

export const textData02 = [
  {
    start: '',
    duration: '10vh',
    properties: [
      {
        startValue: 0,
        endValue: -25,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
  {
    start: '',
    startOffset: '1vh',
    duration: '180vh',
    properties: [
      {
        startValue: 25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

export const textData03 = [
  {
    start: '',
    duration: '20vh',
    properties: [
      {
        startValue: 0,
        endValue: -45,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
  {
    start: '',
    startOffset: '1vh',
    duration: '200vh',
    properties: [
      {
        startValue: 45,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];


export const textDataLast = [
  {
    start: '',
    duration: '20vh',
    properties: [
      {
        startValue: 0,
        endValue: -50,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
  {
    start: '',
    startOffset: '1vh',
    duration: '230vh',
    properties: [
      {
        startValue: 50,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

export const boxData = [
  {
    start: '',
    duration: '10vh',
    properties: [
      {
        startValue: 25,
        endValue: 0,
        unit: 'vh',
        property: 'translateX',
      },
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
  {
    start: '',
    startOffset: '1vh',
    duration: '200vh',
    properties: [
      {
        startValue: 0,
        endValue: 25,
        unit: 'vh',
        property: 'translateX',
      },
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

export const boxData02 = [
  {
    start: '',
    duration: '10vh',
    properties: [
      {
        startValue: -20,
        endValue: 0,
        unit: 'vh',
        property: 'translateX',
      },
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
  {
    start: '',
    startOffset: '1vh',
    duration: '300vh',
    properties: [
      {
        startValue: 0,
        endValue: -20,
        unit: 'vh',
        property: 'translateX',
      },
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];


import React from 'react';
import './scss/App.scss';
import Landing from './screens/landing/Landing';
import Games from './screens/games/Games';
import About from './screens/aboutUs/AboutUs';
import { BrowserRouter as Router, Route } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Route
        exact={true}
        path="/"
        render={() => <Landing />}
      />
      <Route
        path="/jogos"
        render={() => <Games/>}
        />
      <Route
        path="/about"
        render={() => <About/>}
        />
    </Router>
  );
}

export default App;
